import React from 'react'
import Main from '../components/layout'
import Head from '../components/head'
import ErrorImage from '../../assets/error.svg'
import { Heading1, Paragraph } from '../components/typography'

function NotFound() {
  return (
    <Main>
      <Head title="Error" />
      <ErrorImage />

      <Heading1>Lost and Found</Heading1>
      <Paragraph>
        <span role="img" aria-label="Sorry!">
          😭
        </span>
      </Paragraph>
    </Main>
  )
}

export default NotFound
